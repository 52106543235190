<template>
  <div class="content">
    <v-header></v-header>
    <div class="form">
      <div class="data">
        <div class="list">
          <p>公司名称</p>
          <el-input v-model="con.name" placeholder="请输入公司名称" />
        </div>

        <div class="list">
          <p>公司地址</p>
          <el-input v-model="con.address" placeholder="请输入公司地址" />
        </div>
      </div>

      <div class="data">
        <div class="list">
          <p>手机号码</p>
          <el-input v-model="con.phone" placeholder="请输入手机号码" />
        </div>

        <div class="list">
          <p>公司电话</p>
          <el-input v-model="con.gs_phone" placeholder="请输入公司电话" />
        </div>
      </div>

      <div class="data">
        <div class="list">
          <p>微信号码</p>
          <el-input v-model="con.vx_code" placeholder="请输入微信号码" />
        </div>

        <div class="list">
          <p>经营范围</p>
          <el-input v-model="con.fanwei" placeholder="请输入经营范围" />
        </div>
      </div>

      <div class="data">
        <div class="list">
          <p>阿里号</p>
          <el-input v-model="con.al_code" placeholder="请输入阿里号" />
        </div>

        <div class="list">
          <p>京东号</p>
          <el-input v-model="con.jdh_code" placeholder="请输入京东号" />
        </div>
      </div>

      <div class="data">
        <div class="list">
          <p>快手号</p>
          <el-input v-model="con.ks_code" placeholder="请输入快手号" />
        </div>

        <div class="list">
          <p>淘宝号</p>
          <el-input v-model="con.tb_code" placeholder="请输入淘宝号" />
        </div>
      </div>

      <div class="data">
        <div class="list">
          <p>公司分类</p>
          <!-- <el-input v-model="con.name" placeholder="请选择公司分类" /> -->
          <el-select
            style="width: 100%"
            size="medium"
            v-model="con.type"
            placeholder="请选择公司分类"
          >
            <el-option
              v-for="item in productList"
              :key="item.title"
              :label="item.title"
              :value="item.title"
            ></el-option>
          </el-select>
        </div>

        <div class="list">
          <p>抖音号</p>
          <el-input v-model="con.dy_code" placeholder="请输入抖音号" />
        </div>
      </div>

      <div class="data">
        <div class="list">
          <p>初始地</p>
          <el-input v-model="con.start" placeholder="请选择初始地" />
        </div>

        <div class="list">
          <p>到达地</p>
          <el-input v-model="con.arrive" placeholder="请输入到达地" />
        </div>
      </div>

      <div class="data">
        <div class="list" style="width: 100%">
          <p>网站链接</p>
          <el-input v-model="con.url" placeholder="请输入网站链接" />
        </div>
      </div>

      <div class="list" style="width: 100%">
        <el-button type="primary" @click="save">立即提交</el-button>
      </div>
    </div>
    <div
      class="footer"
      style="
        font-size: 14px;
        width: 95%;
        margin: auto;
        border-top: 1px solid #f5f3f7;
        padding-top: 10px;
        text-align: center;
        font-weight: 400;
      "
    >
      Copyright ©2023-2024 德州众搜信息技术有限公司
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >鲁ICP备2023050409号-1</a
      >
    </div>
  </div>
</template>
          
    <script>
import { getType,addProduct } from "@/api/product"
import header from "@/components/header";
export default {
  components: {
    "v-header": header,
  },
  data() {
    return {
      con: {},
      productList: []
    };
  },
  created() {
    getType().then(res => {
        this.productList = res.data
    })
  },
  methods: {
    save() {
        // console.log(this.con)
        addProduct(this.con).then((res) => {
        if (res.code == 200) {
          alert(res.msg);
        } else {
          alert(res.msg);
        }
      });
    },
  },
};
</script>
          
    <style lang="less" scoped>
@import url("./index.less");
</style>